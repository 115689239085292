import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import Fade from 'react-reveal/Fade'
import globals from '../globals'
import Header from '../header/Header'

const useStyles = makeStyles((theme) => ({
    container: {
        display: "inline-block",
        marginBottom: "5vw",
        marginTop: "5vw",
        [theme.breakpoints.up('md')]: {
            margin: "auto",
            marginBottom: "7vw",
            marginTop: "7vw",
            display: "flex",
            maxWidth: globals.maxWidth,
        }
    },
    grid: {
        width: "400px",
        marginTop: "40px",
        minHeight: "350px",
        [theme.breakpoints.up('md')]: {
            textAlign: "center",
        }
    },
    gridText: {
        color: "white",
        fontSize: "36px",
        fontWeight: "500",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
        [theme.breakpoints.up('md')]: {
            fontSize: "36px",
        }
    },
    edificio: {
        marginBottom: "0px",
        color: "white",
        fontFamily: "Oswald, sans-serif",
        fontSize: "36px",
        lineHeight: "56px",
        textTransform: "uppercase",
        fontWeight: "700",
        
        [theme.breakpoints.up('md')]: {
            marginTop: "85px",
            fontSize: "50px",
        }
    },
    sansiro: {
        color: "white",
        fontWeight: 700,
        margin: "0px",
        fontSize: "140px",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
        fontWeight: "bold",
        letterSpacing: "-3px",
        lineHeight: "180px",
        [theme.breakpoints.up('md')]: {
            fontSize: "200px",
        }
    },
    box: {
	    position: "absolute",
	    top: '85%',
	    left: "50%",
	    transform: "translate(-50%, 50%)"
    },
    menor:{
        maxWidth: "700px",
        display: "inline-block"
    },
    maior:{
        width: "100%"
    },
    boxSpan: {
        display: "block",
        width: "20px",
        height: "20px",
        borderBottom: "3px solid #FFCE2D",
        borderRight: "3px solid #FFCE2D",
        transform: "rotate(45deg)",
        margin: "-10px",
        animation: "animate 2s infinite",
        '&:nth-child(2)': {
            animationDelay: "-0.2s"
        },
        '&:nth-child(3)': {
            animationDelay: "-0.4s"
        },
    }
}));

export default function InvestidorMain() {
    const { t } = useTranslation();
    const classes = useStyles()
    return (
        <Grid id="main" container spacing={3}>
            <Header white="true"/>
            <Grid item xs={12} className={classes.grid}>
                <Typography variant="h1" >
                    <Fade left>
                        <p className={classes.edificio}>Investidores</p>
                    </Fade>
                </Typography>                	
            </Grid>
        </Grid>
    )
}