
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import parquePrincipeImg from '../../images/planta-final01.jpg'
import parquePrincipeImg2 from '../../images/planta-final02.jpg'
import parquePrincipeImg3 from '../../images/planta-final03.jpg'
import parquePrincipeImg4 from '../../images/planta-final04.jpg'
import globals from '../globals'
import Carousel from 'react-material-ui-carousel'



const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        display: 'inline-block',
        margin: "auto",
        marginTop: "20px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            maxWidth: "100%"
        },
    },
    responsiveContainer2: {
        paddingRight: "10px"
    },
    logo: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            minWidth: "300px",
        }
    },
}));

export default function SanPaoloCarousel() {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return (
        <Grid id="delivered"  name="delivered" container justify="center" alignItems="center" spacing={3} className={classes.responsiveContainer}>
            <Grid item xs={12} className={classes.responsiveContainer2}>
                <Carousel>
                    <img key="0" src={parquePrincipeImg} alt="parque dos principes" className={classes.logo} />
                    <img key="1" src={parquePrincipeImg2} alt="parque dos principes 2" className={classes.logo} />
                    <img key="2" src={parquePrincipeImg3} alt="parque dos principes 3" className={classes.logo} />
                    <img key="3" src={parquePrincipeImg4} alt="parque dos principes 4" className={classes.logo} />
                </Carousel>
            </Grid>
        </Grid>
    )

}