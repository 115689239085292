import React from 'react'
import Main from '../main/Main'
import Releases from '../releases/Releases'
import Caixa from '../caixa/Caixa'
import ContactUs from '../contactUs/ContactUs'
import KnowMore from '../knowmore/KnowMore'
import { makeStyles } from '@material-ui/core'
import Footer from '../footer/Footer';
import SanPaoloAndamento from '../sanpaoloAndamento/SanPaoloAndamento'
import SanPaoloBuilding from '../sanpaoloBuilding/SanPaoloBuilding'
import SanPaoloText from '../sanpaoloText/SanPaoloText'


const useStyles = makeStyles((theme) => ({
    
}));
export default function Home(props) {
    const classes = useStyles()
    console.log("Starting home with " + JSON.stringify(props))
    if (props.scrollTo) {
        window.addEventListener("load", function(){
            console.log("scrolling to " + props.scrollTo)
            document.getElementById(props.scrollTo).scrollIntoView();
        });
    }
    
    return (
        <div>
            <Main />
            <Caixa />
            <SanPaoloBuilding />
            <Releases />
            <SanPaoloAndamento />
            {/* <Delivered /> */}
            <KnowMore />
            <ContactUs />
            <SanPaoloText />
            <Footer />
        </div>
    )
}