import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal';
import { useTranslation } from "react-i18next";
import globals from '../globals'
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import './ContactForm.css';

function onChange(value) {
    console.log("Captcha value:", value);
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        },
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    selectText: {
        width: "50%",
        display: "block",
        float: "left",
        color: globals.fontColor,
        marginTop: "3vw"
    },
    responsiveInput: {
        width: "100%",
        backgroundColor: "white !important",
        [theme.breakpoints.up('md')]: {
            width: "100% ",
        }
    },
    responsiveInputNumber: {
        width: "100%",
        backgroundColor: "white !important",
        [theme.breakpoints.up('md')]: {
            width: "100% ",
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    responsiveReason: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    responsiveSelect: {
        width: "80%",
        [theme.breakpoints.up('md')]: {
            width: "40%",
            display: "inline-flex",
            float: "left",
            marginTop: "3.5vw !important",
            maxLines: "5"
        }
    },
    message: {
        width: "100%",
        backgroundColor: "white",
        color:"#000",
    },
    itemContato: {
        fontSize: "14px", 
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#FFCE2D", 
        margintop: "70px",
        minWidth: "150px",
        borderRadius: "0px",
        '&:hover': {
            backgroundColor: "#F9D56E",
        },
        [theme.breakpoints.up('md')]: {
            float: "left",
            marginLeft: "8px",  
        }
      },
}));

export default function ContactForm() {
    const recaptchaRef = React.createRef();

    const sendMail = (body) => {
        console.log("sending", JSON.stringify(body));
        axios.post('https://www.sansiroincorporadora.com.br/mail/', {
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        })
        .then((result) => {
            console.log("Result" + JSON.stringify(result));
            setModalMessage("Sua mensagem foi enviada com sucesso, obrigado pelo seu contato!");
            setModalTitle("Obrigado!");
            handleModalOpen()
        }, (error) => {
            console.log(error)
            setModalMessage("Houve um erro ao enviar sua mensagem. Estamos verificando, desculpe-nos pela inconveniencia");
            setModalTitle("Desculpe-nos");
            handleModalOpen()
        });
      }
    const classes = useStyles();
    const { t } = useTranslation();
    const [option, setOption] = React.useState('question');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");
    const [modalTitle, setModalTitle] = React.useState("");
    const [nameData, setNameData] = React.useState({error: false, helperText: ""});
    const [phoneData, setPhoneData] = React.useState({error: false, helperText: ""});
    const [emailData, setEmailData] = React.useState({error: false, helperText: ""});
    const [messageData, setMessageData] = React.useState({error: false, helperText: ""});
    const [form, setForm] = React.useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const changeForm = (field, value) => {
        setForm({ ...form, [field]: value });
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setOption(value);
    };

    const clearInput = (name) => {
        const initialState = {error: false, helperText: ""}; 
        switch (name) {
            case "name":
                setNameData(initialState);
                break;
            case "phone":
                setPhoneData(initialState);
                break;
            case "email":
                setEmailData(initialState);
                break;
            case "message":
                setMessageData(initialState);
                break;
            default: break;
        }
    };

    const validateName = (event) => {
        const val = event.target.value.trim();
        if (val === undefined || val === "") {
            setNameData({error: true, helperText: "Nome não pode ser vazio."});
            return false;
        }

        if (val.length < 4) {
            setNameData({error: true, helperText: "Nome precisa de ao menos 4 caracteres"});
            return false;
        }
        setNameData({error: false, helperText: ""});
        return true
    };

    const validateEmail = (event) => {
        const val = event.target.value.trim();
        if (val === undefined || val === "") {
            setEmailData({error: true, helperText: "Email não pode ser vazio."});
            return false;
        }
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(val)) {
            setEmailData({error: true, helperText: "Email inválido"});
            return false;
        }
        setEmailData({error: false, helperText: ""});
        return true
    };

    const validateMessage = (event) => {
        const val = event.target.value.trim();
        if (val === undefined || val === "") {
            setMessageData({error: true, helperText: "A mensagem não pode ser vazia."});
            return false;
        }

        if (val.length < 3) {
            setMessageData({error: true, helperText: "A mensagem precisa ter ao menos 3 caracteres"});
            return false;
        }
        setMessageData({error: false, helperText: ""});
        return true
    };

    const validatePhone = (event) => {
        const val = event.target.value.replace(/[^0-9]/g, '').trim();
        if (val === undefined || val === "") {
            setPhoneData({error: true, helperText: "O telefone deve ser preenchido."});
            return false;
        }

        if (val.length < 8) {
            setPhoneData({error: true, helperText: "O telefone precisa de ao menos 8 caracteres"});
            return false;
        }
        setPhoneData({error: false, helperText: ""});
        return true
    };

    const validateBody = (body) => {
        let valid = true;
        if (!validateName({target: {value: body.name}})) valid = false;
        if (!validateEmail({target: {value: body.email}})) valid = false;
        if (!validateMessage({target: {value: body.message}})) valid = false;
        return valid;
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue();
        const body = { ...form, "recaptcha": recaptchaValue };
        if (validateBody(body)) {
            sendMail(body)
        } else {
            setModalMessage("Por favor, verifique os campos com erro");
            setModalTitle("Atenção");
            handleModalOpen()
        }
        
    };

    const body = (
        <div style={{ top: `50%`,left: `50%`, transform: `translate(-50%, -50%)`}} className={classes.paper}>
            <h2 id="simple-modal-title">{modalTitle}</h2>
            <p id="simple-modal-description">
                {modalMessage}
            </p>
        </div>
    );

    return (
        <form noValidate autoComplete="off" className={classes.root} style={{ height: '100%' }} onSubmit={handleSubmit}>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            <div><p style={{color: "#353535", textAlign: "left", marginLeft:"5px"}}>*Ao preencher o formulário a seguir, concordo em receber comunicações de acordo com meus interesses.</p></div>
            <div>
                <TextField 
                InputProps={{
                    className: classes.responsiveInput
                  }}
                  required id="name" label={t('name')} error={nameData.error} helperText={nameData.helperText} onBlur={validateName} onFocus={() => {clearInput("name")}} onChange={(e) => { changeForm("name", e.target.value) }} variant="filled"  className={classes.responsiveInput} />
            </div>
            <div>
                <TextField type="number"
                InputProps={{
                    className: classes.responsiveInputNumber
                  }}
                  required id="phone" label={t('phone')} error={phoneData.error} helperText={phoneData.helperText} onBlur={validatePhone} onFocus={() => {clearInput("phone")}} onChange={(e) => { changeForm("phone", e.target.value) }} variant="filled" className={classes.responsiveInput} />
            </div>

            <div>
                <TextField 
                InputProps={{
                    className: classes.responsiveInput
                  }} required id="email" label={t('email')} error={emailData.error} helperText={emailData.helperText} onBlur={validateEmail} onFocus={() => {clearInput("email")}} onChange={(e) => { changeForm("email", e.target.value) }} variant="filled"  className={classes.responsiveInput} />
            </div>

            <TextField
                required
                id="outlined-multiline-static"
                label="Mensagem"
                multiline
                rows={8}
                fullWidth={true}
                defaultValue=""
                variant="filled"
                error={messageData.error} helperText={messageData.helperText} onBlur={validateMessage} onFocus={() => {clearInput("message")}}
                onChange={(e) => { changeForm("message", e.target.value) }}
                className={classes.message}
                InputProps={{
                    className: classes.message
                  }}
            />
            <div className="recaptcha">
                <ReCAPTCHA
                    sitekey="6LeqBiwaAAAAAAXjLTqNLDj4GB3tT43HTxBF749x"
                    onChange={onChange}
                    ref={recaptchaRef}
                />
            </div>
            <div style={{margin:"auto", marginTop: "25px", maxWidth: "100%"}}>
                <Button
                    type="submit"
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.itemContato}
                >
                    Enviar
                </Button>
            </div>
        </form>
    );

}