import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: "100%",
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "auto",
    padding: "50px",
    display: "block",
    
  },
   websitesContainer: {
        backgroundColor: "white",
        display: "flex",
        flexFlow: "column-reverse",
        margin: "auto",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: "15vw",
        paddingRight: "15vw",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",        
        }
    },
    grid: {
        padding: "5px !important",
        width: "100%",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            
        }
    },
    botao: {
        fontSize: "14px", 
        minHeight: "50px",
        minWidth: "150px",
        color: "#353535", 
        backgroundColor: "#FFCE2D", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        position: "relative",
        borderColor: "#FFCE2D",
        borderWidth: "2px",
        border: "solid",
        bottom: "10%",
        marginTop: "70px",
        '&:hover': {
          backgroundColor: "#F9D56E",
          color: "#353535"
        },
    }
}));

export default function TourButton() {
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowMore = () => {
    setShowMore(true);
  };
  const handleShowMoreClose = () => {
    setShowMore(false);
  };
  return (
      <div>
          <Button
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            className={classes.botao}
            onClick={handleOpen}
        >
            Tour Virtual
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div id="fora" style={{
                textAlign: "center",
                maxWidth: "1000px",
                width: "80%",
                height: "80%",
                maxHeight: "1000px",
                margin: "auto",
                display: "table"
            }}>
                <div id="dentro" style={{
                width: "100%",
                height: "100%",
                display: "table-cell",
                verticalAlign: "middle"
                }}>
                    <iframe 
                        src="https://kuula.co/share/NHjrs/collection/7vYCd?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1" 
                        style={{width:"100%", height:"100%"}}
                        title="Tour Virtual"
                    >
                    </iframe>
                </div>
            </div>
        </Modal>
       
        <Modal
            open={showMore}
            onClose={handleShowMoreClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: "scroll"}}
        >
            <div id="outter" style={{
                textAlign: "center",
                maxWidth: "1000px",
                width: "80%",
                height: "80%",
                maxHeight: "1000px",
                margin: "auto",
                display: "table",
                overflow: "scroll",
                backgroundColor: "white"
            }}>
                <div id="inner" style={{
                width: "100%",
                height: "100%",
                display: "table-cell",
                verticalAlign: "middle",
                overflow: "scroll"
                }}>
                    
                
           

                </div>
            </div>
        </Modal>
        
    </div>
  );
}