import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import MoreIcon from '@material-ui/icons/MenuRounded'
import {ReactComponent as SanSiroLogo} from '../../images/logo-sansiro-menu.svg';
import {ReactComponent as SanSiroLogoWhite} from '../../images/logosansiro.svg';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    maxWidth: "100%",
    [theme.breakpoints.up('md')]: {
      width: "100%",
      maxWidth: "1500px",
      margin: "auto",
      overflow: "visible"
    }
  },
  sandwichMenuWhite: {
    color: "white"
  },
  sandwichMenu: {
    color: "#474747"
  },
  offset: theme.mixins.toolbar,
  flags: {
    display: "none",
    fontSize: "3.5vw",
    marginRight: "1vw",
    cursor: "pointer",
    [theme.breakpoints.up('md')]: {
      fontSize: "25px",
      marginRight: "1vw",
    }
  },
  bar: {
    backgroundColor: "white",
  },
  mobileMenuItem: {
    color: "#1c3b4c",
    fontSize: "2vw",
    width: "100%"
  },
  mobileMenuItemQuote: {
    backgroundColor: "#f4ab0b !important",
  },
  mobileMenuButton: {
    width: "100%",
    fontSize: "2vw",
  },
  menuItem2: {
    color: "#7a7a7a",
    fontWeight: "500",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "0px",
    height: "40px",
    '&:hover': {
      color: "#FFCE2D",
      textDecoration: "none",
      backgroundColor: "transparent"
    },
  },
  menuItem2White: {
    color: "white",
    fontWeight: "500",
    fontSize: "16px",
    textDecoration: "none",
    borderRadius: "0px",
    height: "40px",
    '&:hover': {
      color: "#FFCE2D",
      textDecoration: "none",
      backgroundColor: "transparent"
    },
  },
  itemContato: {
    fontSize: "14px", 
    color: "#353535", 
    backgroundColor: "#FFCE2D", 
    paddingLeft: "15px",
    paddingRight: "15px",
    width: "110px",
    borderRadius: "0px",
    maxHeight: "40px",
    marginTop: "inherit",
    '&:hover': {
      backgroundColor: "#F9D56E",
    },
  },
  grow: {
    flexGrow: 1,
    height: "65px",
    width: "70%",
    [theme.breakpoints.up('md')]: {
      height: "auto",
      width: "15vw"
    }
  },
  logo: {
    width: "100px", 
    marginTop: "10px",
    [theme.breakpoints.up('md')]: {
      width: "150px", 
      marginTop: "25px",
    }
  },
  logoWhite: {
    width: "100px", 
    marginTop: "10px",
    [theme.breakpoints.up('md')]: {
      width: "140px", 
      marginTop: "25px",
    }
  },
  sectionDesktop: {
    display: 'none',
    marginTop: "10px",
    marginBottom: "15px",
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      float: "right"
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  popper: {
    zIndex: "99",
    color: "black"
  },
  menuButton: {
    backgroundColor: "transparent",
    textDecoration: "none", 
    padding: "8px 12px",
    color: "#696969",
    fontSize: "16px",
    '&:hover': {
      color: "#FFCE2D", 
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  },
  menuButtonWhite: {
    backgroundColor: "transparent",
    textDecoration: "none", 
    padding: "8px 12px",
    color: "#696969",
    fontSize: "16px",
    '&:hover': {
      color: "#696969", 
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  },
  menuButton2: {
    backgroundColor: "transparent",
    textDecoration: "none", 
    padding: "8px 12px",
    color: "#696969",
    fontSize: "16px",
    marginRight: "20px",
    '&:hover': {
      color: "#FFCE2D", 
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  },
  menuButton2White: {
    backgroundColor: "transparent",
    textDecoration: "none", 
    padding: "8px 12px",
    color: "white",
    fontSize: "16px",
    marginRight: "20px",
    '&:hover': {
      color: "#696969", 
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  },
  menu: {
    '&:hover': {
      textDecoration: "none",
      backgroundColor: "white"
    }
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const click = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const click2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const close2 = () => {
    setAnchorEl2(null);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  const handleMobileMenuClose = (event) => {
    event.preventDefault();
    console.log("mobileMoreAnchorEl", mobileMoreAnchorEl);
    console.log("anchorREF", anchorRef);
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const gotoRef = (id) => {
    document.getElementById(id).scrollIntoView();
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Popper open={isMobileMenuOpen} anchorEl={mobileMoreAnchorEl} role={undefined} transition className={classes.popper}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMobileMenuClose}>
              <MenuList autoFocusItem={isMobileMenuOpen} id={mobileMenuId} style={{marginTop: "80px"}}>
                {/* <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/edificio-sanpaolo`} style={{textDecoration: "none", width: "100%"}}>
                      <Button className={classes.mobileMenuButton} aria-label="edificio sanpaolo" color="inherit">
                        <p>Edifício Sanpaolo</p>
                      </Button>
                  </Link>
                </MenuItem> */}
                <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/lancamentos`} style={{textDecoration: "none", width: "100%"}} replace>
                    <Button className={classes.mobileMenuButton} aria-label="lancamentos" color="inherit">
                      <p>Futuros</p>
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/edificio-sansiro`} style={{textDecoration: "none", width: "100%"}}>
                      <Button className={classes.mobileMenuButton} aria-label="edificio sansiro" color="inherit">
                        <p>Edifício Sansiro</p>
                      </Button>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/investidor`} style={{textDecoration: "none", width: "100%"}}>
                    <Button className={classes.mobileMenuButton} aria-label="investidor" color="inherit">
                      <p>{t('investidor')}</p>
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/imobiliarias`} style={{textDecoration: "none", width: "100%"}}>
                    <Button className={classes.mobileMenuButton} aria-label="imobiliarias" color="inherit">
                      <p>{t('realstates')}</p>
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.mobileMenuItem}>
                  <Link to={`/sobre`} style={{textDecoration: "none", width: "100%"}}>
                      <Button className={classes.mobileMenuButton} aria-label="sobre a san siro" color="inherit">
                        <p>{t('about')}</p>
                      </Button>
                  </Link>
                </MenuItem>
                <MenuItem className={classes.mobileMenuItem}>
                  <Button className={classes.mobileMenuButton} aria-label="send us a message" color="inherit" onClick={() => { gotoRef("contactUs") }}>
                    <p>{t('contact')}</p>
                  </Button>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
  return (
    <div id="header" className={classes.grow}>
      <AppBar elevation={0} position="absolute" className={classes.bar}>
        <div className={classes.header}>
          <Toolbar style={{paddingLeft: "12px"}}>
            <Link to={`/`} style={{textDecoration: "none"}}>
              <SanSiroLogo  className={classes.logo}/>
            </Link>
            <div style={{float: "right", width: "100%", textAlign: "right"}}>
              <MenuList className={classes.sectionDesktop}>              
                {/* <Button 
                  aria-owns={anchorEl ? "menu-releases" : undefined}
                  aria-haspopup="true" 
                  onMouseOver={click2} 
                  onClick={click2} 
                  className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                >
                  {t('releases')}
                </Button> */}
                {/* <Menu
                  id="menu-releases"
                  anchorEl={anchorEl2}
                  keepMounted
                  open={Boolean(anchorEl2)}
                  onClose={close2}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  className={classes.menu}
                  MenuListProps={{
                    classes: {marginTop: "-100px"},
                    style: {
                      height: "40px",
                      marginTop: "0px",
                      margin: "0px"
                    },
                    onMouseLeave: close2
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      textAlign: "center",
                      margin: "0px",
                      padding: "0px",
                      marginTop: "-15px"
                    }
                  }}
                >
                  <MenuItem onClick={close2} className={`${props.white === "true" ? classes.menuItem2White : classes.menuItem2 }`}>
                    <Link to={`/edificio-sanpaolo`} style={{textDecoration: "none"}}>
                      <Button
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        className={`${props.white === "true" ? classes.menuItem2White : classes.menuItem2 }`}
                      >
                        San Paolo
                      </Button>
                    </Link>
                  </MenuItem>
                </Menu> */}

                  <Link to={`/lancamentos`} className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                    >
                      Futuros
                    </Button>
                  </Link>

                <Button 
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true" 
                  onMouseOver={click} 
                  onClick={click} 
                  className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                >
                  Entregues
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={close}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  MenuListProps={{
                    style: {
                      height: "40px",
                      marginTop: "0px",
                      margin: "0px",
                    },
                    onMouseLeave: close
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      textAlign: "center",
                      margin: "0px",
                      padding: "0px",
                      marginTop: "-15px"
                    }
                  }}
                >
                  <MenuItem onClick={close} className={`${props.white === "true" ? classes.menuItem2White : classes.menuItem2 }`}>
                    <Link to={`/edificio-sansiro`} style={{textDecoration: "none"}}>
                      <Button
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        className={`${props.white === "true" ? classes.menuItem2White : classes.menuItem2 }`}
                      >
                        Sansiro
                      </Button>
                    </Link>
                  </MenuItem>
                </Menu>
                <Link to={"/investidor"} className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}>
                  <Button
                    aria-haspopup="true"
                    className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                  >
                    {t('investidor')}
                  </Button>
                </Link>
                <Link to={"/imobiliarias"} className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}>
                  <Button
                    aria-haspopup="true"
                    className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                  >
                    {t('realstates')}
                  </Button>
                </Link>
                <Link to={`/sobre`} className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}>
                  <Button
                    aria-haspopup="true"
                    className={`${props.white === "true" ? classes.menuButtonWhite : classes.menuButton }`}
                  >
                    {t('whoweare')}
                  </Button>
                </Link>
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => { gotoRef("contactUs") }}
                  color="inherit"
                  className={classes.itemContato}
                >
                  {t('contact')}
                </Button>
              </MenuList>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                className={`${props.white === "true" ? classes.sandwichMenuWhite : classes.sandwichMenu }`}
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      {renderMobileMenu}
      <div className={classes.offset} />
    </div>
  );
}
