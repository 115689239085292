import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next";
import globals from '../globals'
import Fade from 'react-reveal/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import cardBg from '../../images/icone-embreve.png';
import cardBgPraia from '../../images/img-embreve-praia.jpg';
import tabatingaImg from '../../images/sansiro-tabatinga-embreve-02.jpg';
import massaguacuImg from '../../images/sansiro-massaguacu-embreve-03.jpg';


const useStyles = makeStyles((theme) => ({
    aboutUsContainer: {
        display: "inline-block",
        paddingLeft: "20px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            display: "flex",
            overflow: "hidden",
            margin: "auto",
            maxWidth: "1500px"
        }
    },
    aboutUs: {
        color: globals.titleColor,
        fontWeight: 800,
        fontFamily: 'Roboto',
        lineHeight: "85%",
        fontSize: globals.titleSize,
        textTransform: "uppercase",
        marginTop: "100px",
        [theme.breakpoints.up('md')]: {
            fontWeight: 800,
            fontFamily: 'Roboto',
            lineHeight: "85%",
        }
    },
    aboutUsTextArea: {
        fontFamily: 'Roboto',
        fontSize: "25px",
        color: globals.fontColor,
        marginBottom: "100px",
        [theme.breakpoints.up('md')]: {
            fontSize: globals.textSize,
        }
    },
    responsiveGrid: {
        maxWidth: "300px",
        backgroundColor: "transparent",
        margin: "auto",
        marginBottom:"100px",
        marginTop:"40px"
    },
    card: {
        height: "280px",
        width: "280px",
        borderRadius: "0px",
        border: "0",
        backgroundImage: `url(${cardBg})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#dfdfdf",
        backgroundPositionY: "bottom",
        backgroundPositionX: "center",
        display: "none",
        [theme.breakpoints.up('md')]: {
            display: "inherit"
        }
    },
    cardPraia: {
        height: "280px",
        width: "280px",
        textAlign: "left",
        borderRadius: "0px",
        border: "0",
        backgroundImage: `url(${tabatingaImg})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#dfdfdf",
        backgroundPositionY: "bottom",
        backgroundPositionX: "center",
        backgroundSize: "cover"
    },
    cardMassaguacu: {
        height: "280px",
        width: "280px",
        textAlign: "left",
        borderRadius: "0px",
        border: "0",
        backgroundImage: `url(${massaguacuImg})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#dfdfdf",
        backgroundPositionY: "bottom",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        marginTop: "50px",
        [theme.breakpoints.up('md')]: {
            marginTop: "inherit",
            display: "inherit"
        }
    },
    massagua: {
        fontSize: "30px",
        fontWeight: "800",
        fontStyle: "italic",
        textTransform: "uppercase",
        lineHeight: "32px",
    },
    cardContent: {
        marginTop: "45px",
        color: "white",
        paddingLeft: "80px",
        paddingRight: "70px",
        height: "100%",
        minHeight: "190px",
    },
    avatar: {
        backgroundColor: globals.fontColor
    },
    title1: {
        fontSize: "30px",
        fontWeight: "normal",
        color: "white",
        lineHeight: "0px",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.up('md')]: {
            margin: "0px",
            lineHeight: "inherit"
        }
    },
    title2: {
        fontSize: "36px",
        fontWeight: 900,
        color: "white",
        textTransform: "uppercase",
        fontFamily: "Oswald, sans-serif",
        [theme.breakpoints.up('md')]: {
            margin: "0px",
        }
    },
    brandingContainer: {
        backgroundColor: "white",
        display: "inline-block",
        margin: "auto",
        marginBottom: "40px",
        [theme.breakpoints.up('md')]: {
            display: "flex",
            maxWidth: globals.maxWidth,
        },
    },
    brandingTopDiv: {
        marginTop: "40px",
    },
    brandingLeftDiv: {
        maxWidth: "100%",
        margin: "0px",
    },
    brandingRightDiv: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "60px !important",
            paddingLeft: "2vw !important",
        }
    },
    brandingGridText: {
        fontWeight: "normal",
        fontFamily: "Poppins, sans-serif",
        fontSize: "20px",
        marginTop: "45%",
        color: "#474747"
    },
    brandingTextArea: {
        fontFamily: "Oswald, sans-serif",
        fontWeight: "bold",
        fontSize: "50px",
        textTransform: "uppercase",
        marginBottom: "10px"
    },
    itemContato: {
        fontSize: "14px", 
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#FFCE2D", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        position: "relative",
        bottom: "10%",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
      tabatinga: {
          fontSize: "34px",
          fontWeight: "800",
          fontStyle: "italic",
          textTransform: "uppercase",
          lineHeight: "32px",
      },
      tabatingaInfo: {
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "uppercase"
      },
      tabatingaCardContent: {
        color: "white",
        paddingTop: "0px",
        maxWidth: "200px",
        margin: "auto"
    },
    releases: {
        backgroundColor: "#353535", 
        maxWidth: "100%", 
        maxHeight: "1100px",
        marginRight: "0px",
        marginLeft: "0px",
        [theme.breakpoints.up('md')]: { 
            minHeight:"800px",
            margin:"auto", 
        }
    }
}));

export default function Releases() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="releases"  name="releases" container spacing={3} className={classes.releases}>
            <Grid container spacing={3} className={classes.aboutUsContainer}>
                <Grid  item xs={12} className={classes.brandingTopDiv}>
                    <Typography variant="h2" >
                        <p className={classes.title1}>{t('know our')}</p>
                        <p className={classes.title2}>{t('future releases')}</p>
                    </Typography>
                </Grid>
                <Grid id="solutions1" item xs={3} className={classes.responsiveGrid}>
                    <Fade>
                        <Card className={classes.cardPraia} variant="outlined">
                            <CardContent className={classes.tabatingaCardContent}>
                                <p className={classes.tabatinga}>Praia de Tabatinga</p>
                                <p className={classes.tabatingaInfo}>+ Informacões em breve </p>
                            </CardContent>
                        </Card>
                    </Fade>
                </Grid>
                <Grid id="solutions2" item xs={3} className={classes.responsiveGrid}>
                    <Fade>
                    <Card className={classes.cardMassaguacu} variant="outlined">
                            <CardContent className={classes.tabatingaCardContent}>
                                <p className={classes.massagua}>Praia de Massaguaçu</p>
                                <p className={classes.tabatingaInfo}>+ Informacões em breve </p>
                            </CardContent>
                        </Card>
                    </Fade>
                </Grid>
                <Grid id="solutions2" item xs={3} className={classes.responsiveGrid}>
                    <Fade>
                        <Card className={classes.card} variant="outlined">
                            <CardContent className={classes.cardContent}>
                                <p className={classes.brandingGridText}>EM BREVE</p>
                            </CardContent>
                        </Card>
                    </Fade>
                </Grid>
                <Grid id="solutions2" item xs={3} className={classes.responsiveGrid}>
                    <Fade>
                        <Card className={classes.card} variant="outlined">
                            <CardContent className={classes.cardContent}>
                                <p className={classes.brandingGridText}>EM BREVE</p>
                            </CardContent>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    )
}