import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import Fade from 'react-reveal/Fade'
import logoCaixa from '../../images/img-logo-caixa.png'

const useStyles = makeStyles((theme) => ({
    grid: {
        maxWidth: "100%",
        backgroundColor: "white",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            margin: "auto",
            display: "block",
            float: "center"
        }
    },
    text: {
        fontSize: "30px",
        fontWeight: "400",
        fontStyle: "italic",
        fontFamily: "Oswald, sans-serif",
        maxWidth: "100%",
        paddingBottom: "70px",
        textAlign:"center",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            borderBottom: "none",
            paddingBottom: "0px",
        },
    },
    main: {
        minHeight: "200px",
        maxWidth: "100%",
        marginTop: "20px",
        marginBottom: "20px",
        display: "block"
    },
    caixa: {
        float: "center",
        marginTop: "20px"
    },
    grid2: {
        maxWidth: "100%",
        maxHeight: "120px",
        backgroundColor: "white",
        textAlign: "center",
        backgroundColor: "#2C66AB",
        color: "white",
        paddingTop: "35px",
        [theme.breakpoints.up('md')]: {
            margin: "auto",
            display: "block",
            height: "100px",
            
        }
    },
    
}));

export default function Caixa() {
    const { t } = useTranslation();
    const classes = useStyles()
    return (
        <Grid container className={classes.main}>
            <Grid id="l1" item sm={12} md={12} className={classes.grid}>
                <Fade left>
                    <img src={logoCaixa} alt="caixa" className={classes.caixa}/>
                </Fade>
            </Grid>
            <Grid id="l2" item sm={12} md={12} className={classes.grid2}>
                    <Typography variant="h1" className={classes.text}>
                        <Fade left>
                            Financiamento pela CAIXA! USE SEU FGTS!
                        </Fade>
                    </Typography>
            </Grid>
        </Grid>
    )
}