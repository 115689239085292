import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ptBR from './locales/default.ptBR.json'
import en from './locales/default.json'

const resources = {
  en: en,
  ptBR: ptBR
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "ptBR",
    fallbackLng: 'ptBR',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      wait: false,
    }
  });

export default i18n;