import React from 'react'
import SanPaoloDetails2 from '../details2/SanPaoloDetails2'
import SanPaoloDetails from '../details/SanPaoloDetails'
import SanPaoloDetails3 from '../sanpaoloDetails3/SanPaoloDetails3'
import ContactUs from '../contactUs/ContactUs'
import { makeStyles } from '@material-ui/core'
import KnowMore from '../knowmore/KnowMore'
import backgroundImage from '../../images/img-header-sanpaolo.jpg'
import Footer from '../footer/Footer';
import SanPaoloMoreDetails from '../sanpaoloMoreDetails/SanPaoloMoreDetails'
import SanPaoloCarousel from '../sanpaoloCarousel/SanPaoloCarousel'
import SanPaoloMainComplement from '../main/SanPaoloMainComplement'
import SanPaoloBuildingMain from '../sanpaoloBuildingMain/SanPaoloBuildingMain'
import SanPaoloVideo from '../sanpaoloVideo/SanPaoloVideo'
import SanPaoloText from '../sanpaoloText/SanPaoloText'
import VirtualTourSanPaolo from '../virtualtour-sanpaolo/VirtualTourSanPaolo'

const useStyles = makeStyles((theme) => ({
    container: {
        
    },
    buildingDiv: {
        minHeight: "600px",
        backgroundRepeat: "no-repeat", 
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImage})`,
        [theme.breakpoints.up('md')]: {
            minHeight: "950px"
        }
    }
}));
export default function SanPaoloBuilding(props) {
    const classes = useStyles()
    if (props.scrollTo) {
        window.addEventListener("load", function(){
            console.log("scrolling to " + props.scrollTo)
            document.getElementById(props.scrollTo).scrollIntoView();
        });
    }
    
    return (
        <div>
            {/* <SanPaoloBuildingMain /> */}
            <SanPaoloMainComplement />
            <SanPaoloDetails more="false" />
            <SanPaoloDetails2 />
            <SanPaoloDetails3 /> 
            <VirtualTourSanPaolo />
            <SanPaoloCarousel />
            <SanPaoloMoreDetails />
            <SanPaoloVideo />
            {/* <KnowMore /> */}
            {/* <ContactUs /> */}
            {/* <SanPaoloText /> */}
            {/* <Footer /> */}
        </div>
    )
}