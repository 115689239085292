import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import Fade from 'react-reveal/Fade'
import Header from '../header/Header'
import logoSanPaolo from '../../images/logo-sanpaolo-cinza-pg-sanpaolo.png'
import backgroundImage from '../../images/img-header-sanpaolo.jpg'

const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: "600px",
        backgroundRepeat: "no-repeat", 
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImage})`,
        [theme.breakpoints.up('md')]: {
            minHeight: "950px"
        }
    },
    grid: {
        margin: "0px"
    },
    leftGrid: {
        backgroundColor: "transparent",
        margin: "auto",
        marginBottom:"auto",
        marginTop:"0px",
        marginLeft: "auto",
        maxWidth: "100%",
        width: "100%",
        padding: "0px",
        textAlign: "center",
        paddingTop: "0px",
        [theme.breakpoints.up('md')]: {
            maxWidth: "600px",
            float: "none",
            marginLeft: "15vw",
            marginRight: "15vw",
            marginBottom:"0px",
            marginTop:"0px"
        }
    },
    rightGrid: {
        maxWidth: "0px",
        [theme.breakpoints.up('md')]: {
        }
    },
    breveLancamento: {
        textTransform: "uppercase",
        backgroundColor: "#f7a50f",
        color: "white",
        fontFamily: "Oswald, sans-serif",
        fontSize: "50px",
        maxWidth: "250px",
        margin: "auto",
        marginTop: "25px",
        marginBottom: "0px",
        fontWeight: "400",
        [theme.breakpoints.up('md')]: {
            marginTop: "20px",
            marginLeft: "0px",
            maxWidth: "300px",
            padding: "0px"
        }
    },
    litoralNorte: {
        padding: "0px",
        marginTop: "10px",
        color: "white",
        fontFamily: "Oswald, sans-serif",
        fontSize: "32px",
        textTransform: "uppercase",
        fontWeight: "300",
        backgroundColor: "#548794",
        maxWidth: "200px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            marginTop: "20px",
            fontSize: "30px",
            maxWidth: "250px",
            marginLeft: "0px",
        }
    },
    logoSanPaolo: {
        marginLeft: "0px",
        marginTop: "25px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
            float: "left"
        }
    },

    cadastreseDiv: {
        display: "block"
    }
}));

export default function SanPaoloBuildingMain() {
    const { t } = useTranslation();
    const classes = useStyles()
    return (
        <Grid id="main" container spacing={3} className={classes.main}>
            <Header />
            <Grid container spacing={3} className={classes.grid}>
                <Grid id="leftGrid" item xs={5} className={classes.leftGrid}>
                    <div id="cadastreseDiv1" className={classes.cadastreseDiv}>
                        <Typography variant="h1" style={{textAlign:"center"}}>
                            <Fade left>
                                <p className={classes.breveLancamento}>{t('breveLancamento')}</p>
                                <p className={classes.litoralNorte}>{t('litoralNorte')}</p>
                            </Fade>
                        </Typography>
                    </div>
                
                    <Fade>
                        <div id="cadastreseDiv2" style={{overflow:"auto"}}>
                            <img src={logoSanPaolo} alt="logo san paolo" className={classes.logoSanPaolo} />
                        </div>
                    </Fade>  
                </Grid>
                <Grid id="rightGrid" item xs={7} className={classes.rightGrid}>
                </Grid>
            </Grid>
        </Grid>
    )
}