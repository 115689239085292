import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import Fade from 'react-reveal/Fade'

const useStyles = makeStyles((theme) => ({
    grid: {
        maxWidth: "100%",
        backgroundColor: "white",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            margin: "auto",
            display: "block",
            maxWidth: "30%"
        }
    },
    innerDiv: {
        textAlign:"center",
        minHeight: "250px",
        [theme.breakpoints.up('md')]: {
            minHeight: "inherit",
            float:"right",
            textAlign:"center"
        }
    },
    innerDivR: {
        textAlign:"center",
        minHeight: "150px",
        [theme.breakpoints.up('md')]: {
            minHeight: "inherit",
            float:"right",
            textAlign:"center"
        }
    },
    innerDivL: {
        textAlign:"center",
        minHeight: "250px",
        [theme.breakpoints.up('md')]: {
            minHeight: "inherit",
            float:"right",
            textAlign:"center"
        }
    },
    ltext: {
        fontSize: "50px",
        fontWeight: "700",
        fontFamily: "Oswald, sans-serif",
        maxWidth: "370px",
        borderRight: "none",
        borderBottom: "2px solid #f7a50f",
        paddingBottom: "70px",
        textAlign:"center",
        [theme.breakpoints.up('md')]: {
            float: "right",
            borderBottom: "none",
            borderRight: "2px solid #f7a50f",
            paddingBottom: "0px",
        },
        margin: "auto",
        color: "#474747",
        
    },
    text: {
        fontSize: "50px",
        fontWeight: "700",
        fontFamily: "Oswald, sans-serif",
        maxWidth: "370px",
        color: "#474747",
        borderBottom: "2px solid #f7a50f",
        paddingBottom: "70px",
        textAlign:"center",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            borderBottom: "none",
            paddingBottom: "0px",
        },
    },
    rtext: {
        fontSize: "50px",
        fontWeight: "700",
        fontFamily: "Oswald, sans-serif",
        maxWidth: "370px",
        borderLeft: "none",
        color: "#474747",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            float: "left",
            borderLeft: "2px solid #f7a50f",
        }
    },
    main: {
        minHeight: "300px",
        paddingTop: "60px",
        paddingBottom: "60px",
        paddingLeft: "13%",
        paddingRight: "13%",
        maxWidth: "100%"
    }
}));

export default function SanPaoloMainComplement() {
    const { t } = useTranslation();
    const classes = useStyles()
    return (
        <Grid container spacing={3} className={classes.main}>
                <Grid id="lGrid" item sm={12} md={4} className={classes.grid}>
                    <div className={classes.innerDivL}>
                        <Typography variant="h1" className={classes.ltext}>
                            <Fade left>
                                02 OU 03 DORMITÓRIOS
                            </Fade>
                        </Typography>
                    </div>
                </Grid>
                <Grid id="mGrid" item sm={12} md={4} className={classes.grid}>
                    <div className={classes.innerDiv}>
                        <Typography variant="h1" className={classes.text}>
                            <Fade left>
                                60m² A 82m² COM 01 SUÍTE
                            </Fade>
                        </Typography>
                    </div>
                </Grid>
                <Grid id="rGrid" item sm={12} md={4} className={classes.grid}>
                    <div className={classes.innerDivR}>
                        <Typography variant="h1" className={classes.rtext}>
                            <Fade left>
                                01 OU 02 VAGAS DE GARAGEM
                            </Fade>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
    )
}