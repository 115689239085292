import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import Fade from 'react-reveal/Fade'
import Header from '../header/Header'
import CadastreSe from '../cadastrese/CadastreSe'
import logoSanPaoloBranco from '../../images/logo-sanpaolo-branco.png'
import backgroundImage from '../../images/img-header-desk.jpg'
import backgroundImageMobile from '../../images/img-header-mob.jpg'

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "0px",
        paddingRight: "0px",
        display: "block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        maxWidth: "100%",
        marginBottom: "0px",
        minHeight: "700px",
        height: "100%",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "auto",
            backgroundAttachment: "inherit",
        }
    },
    main2: {
        margin: "0px",
        display: "block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${backgroundImageMobile})`,
        maxWidth: "100%",
        height: "150vw",
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${backgroundImage})`,
            width: "auto",
            height: "55vw",
            minHeight: "800px",
            backgroundAttachment: "inherit",
        }
    },
    grid: {
        width: "100% !important"
    },
    leftGrid: {
        backgroundColor: "transparent",
        margin: "auto",
        marginBottom:"auto",
        marginTop:"auto",
        float: "center",
        marginLeft: "auto",
        maxWidth: "100%",
        padding: "0px",
        textAlign: "center",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
        [theme.breakpoints.up('md')]: {
            maxWidth: "600px",
            float: "none",
            marginLeft: "15vw",
            marginRight: "15vw",
            marginBottom:"0px",
            marginTop:"40px"
        }
    },
    breveLancamento: {
        textTransform: "uppercase",
        backgroundColor: "#f7a50f",
        color: "white",
        fontFamily: "Oswald, sans-serif",
        fontSize: "36px",
        maxWidth: "300px",
        margin: "auto",
        marginTop: "25px",
        marginBottom: "0px",
        fontWeight: "400",
        [theme.breakpoints.up('md')]: {
            marginTop: "20px",
            marginLeft: "0px",
            fontSize: "50px",
            maxWidth: "400px",
            padding: "0px"
        }
    },
    litoralNorte: {
        padding: "0px",
        marginTop: "10px",
        color: "white",
        fontFamily: "Oswald, sans-serif",
        fontSize: "24px",
        textTransform: "uppercase",
        fontWeight: "300",
        backgroundColor: "#548794",
        maxWidth: "200px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            marginTop: "20px",
            fontSize: "30px",
            maxWidth: "250px",
            marginLeft: "0px",
        }
    },
    logoSanPaolo: {
        marginLeft: "0px",
        marginTop: "25px",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
            width: "350px",
            float: "left"
        }
    },

    cadastreseDivGray: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            backgroundColor: "#EBEBEB",
            paddingTop: "50px",
            paddingBottom: "80px",
            backgroundColor: "transparent",
            paddingTop: "0px",
            paddingBottom: "0px",
            display: "block",
        }
    },
    cadastreseDiv: {
        display: "block",
    },
    divImagem: {
        width: "100%",
        minHeight: "735px",
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    },
    imagem: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    },
    cadastreseDivGray2: {
        backgroundColor: "#EBEBEB",
        paddingTop: "50px",
        paddingBottom: "80px",
        display: "block",
        [theme.breakpoints.up('md')]: {
            display: "none",
        }
    },
}));

export default function Main() {
    const { t } = useTranslation();
    const classes = useStyles()
    return (
        <Grid id="main" container spacing={3} className={classes.main}>
            <Header white="false" />
            <Grid id="main2" container spacing={3} className={classes.main2}>
                <Grid id="leftGrid" item xs={5} className={classes.leftGrid}>
                    <div id="cadastreseDiv1" className={classes.cadastreseDiv}>
                        <Typography variant="h1" style={{textAlign:"center"}}>
                            <Fade left>
                                <p className={classes.breveLancamento}>Obras Aceleradas</p>
                                <p className={classes.litoralNorte}>{t('litoralNorte')}</p>
                            </Fade>
                        </Typography>
                    </div>
                
                    <Fade>
                        <div id="cadastreseDiv2" style={{overflow:"auto"}}>
                            <img src={logoSanPaoloBranco} alt="logo san paolo" className={classes.logoSanPaolo} />
                        </div>
                    </Fade>

                    <div id="cadastreseDiv4" className={classes.cadastreseDivGray}>
                        <Fade>
                            <CadastreSe />
                        </Fade>
                    </div>
                </Grid>
            </Grid>
            <Grid id="cadastreseDiv5" containerspacing={3} className={classes.cadastreseDivGray2}>
                <Fade>
                    <CadastreSe />
                </Fade>
            </Grid>
        </Grid>
    )
}