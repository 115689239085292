import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import localizacaoImg from '../../images/img-localizacao-sanpaolo.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    websitesContainer: {
        backgroundColor: "#efefef",
        display: "flex",
        flexFlow: "column-reverse",
        margin: "auto",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: "8vw !important",
        paddingRight: "8vw !important",
        paddingTop: "20px",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",
            marginTop: "15px",
            maxWidth: "100%"
        }
    },
    websitesLeftDiv: {
        maxWidth: "100%",
        margin: "auto",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "8vw!important",
            marginTop: "0px",
            backgroundColor: "transparent"
        }
    },
    websitesRightDiv: {
        maxWidth: "100%",
        paddingTop: "6vw !important"
    },

    websitesText: {
        color: "#548794",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 700,
        fontSize: "34px",
        textTransform: "uppercase",
        maxWidth: "450px",
        lineHeight: "35px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            marginLeft: "0px"
        }
    },
    websitesTextArea: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        color: "#606060",
        lineHeight: "35px"
    },
    websitesImage: {
        objectFit: "cover",
        height: "auto",
        width: "60%",
        [theme.breakpoints.up('md')]: {
            width: "75%",
        }
    },
    itemContato: {
        fontSize: "20px", 
        fontWeight: "400",
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#f7a50f", 
        borderRadius: "0px",
        margin: "auto",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginTop: "20px",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
      websitesContainerMore: {
        backgroundColor: "#efefef",
        maxWidth: "100%",
        textAlign: "center",
        display: "block",
        paddingBottom:"40px",
        [theme.breakpoints.up('md')]: {
            
        }
    },
}));

export default function Details(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div style={{textAlign: "center"}}>
        <Grid id="details" container spacing={3} className={classes.websitesContainer}>
            <Grid id="detailsDiv" item xs={6} className={classes.websitesLeftDiv}>
                <Typography variant="h1" >
                    <Fade left>
                        <p className={classes.websitesText}>Tenha o privilégio de morar a poucos metros da praia e do shopping</p>
                    </Fade>
                </Typography>
                <Typography variant="h5" >
                    <Fade>
                        <p className={classes.websitesTextArea}>
                            Localizado na Praça Eng. M. Parolari, Praia de Porto Novo, em Caraguatatuba, próximo ao comércio local, a 10 minutos do Shopping Serra Mar e grandes marcas.
                        </p>
                    </Fade>
                </Typography>
            </Grid>
            <Grid id="websitesRight" item xs={6} className={classes.websitesRightDiv}>
                <img src={localizacaoImg} alt="websites" className={classes.websitesImage} />
            </Grid>
        </Grid>
        {props.more === "true" &&
        <Grid id="detailsMore" container  className={classes.websitesContainerMore}>
            <Link to="/edificio-sanpaolo" style={{textDecoration: "none"}}>
                <Button
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.itemContato}
                >
                    Quero saber mais!
                </Button>
            </Link>
        </Grid>
        }
        </div>
    )
}