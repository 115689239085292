import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import brandingImage from '../../images/img-varanda-sanpaolo.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
import globals from '../globals'

const useStyles = makeStyles((theme) => ({
    detailsContainer: {
        backgroundColor: "#efefef",
        margin:"auto", 
        paddingBottom: "80px",
        maxWidth: "100%",
        paddingLeft: "8vw !important",
        paddingRight: "8vw !important",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%"    
        }
    },
    brandingContainer: {
        backgroundColor: "#efefef",
        display: "inline-block",
        margin: "auto",
        maxWidth: "100%",
        
        [theme.breakpoints.up('md')]: {
            display: "flex",
            margin:"auto"
        },
    },
    brandingTopDiv: {
        marginTop: "40px",
    },
    brandingLeftDiv: {
        maxWidth: "100%",
        margin: "0px",
    },
    brandingRightDiv: {
        maxWidth: "100%",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "8vw !important",
            paddingLeft: "2vw !important",
        }
    },
    brandingImage: {
        width: "80%",
        margin: "0px",
        height: "auto",
        [theme.breakpoints.up('md')]: {
            width: "100%",
            marginTop: "60px",
        }
    },
    title1: {
        fontSize: globals.titleSize,
        fontWeight: 900,
        color: globals.titleColor,
        lineHeight: "0px",
        [theme.breakpoints.up('md')]: {
            margin: "0px",
            lineHeight: "inherit"
        }
    },
    title2: {
        fontSize: globals.titleSize,
        fontWeight: 900,
        color: globals.titleColor,
        textTransform: "uppercase",
        [theme.breakpoints.up('md')]: {
            margin: "0px",
        }
    },
    brandingText: {
        color: "#548794",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 700,
        fontSize: "34px",
        textTransform: "uppercase",
        maxWidth: "450px",
        lineHeight: "35px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            marginTop: "70px"
        }
    },
    brandingGridText: {
        color: globals.titleColor,
        fontWeight: "bold"
    },
    brandingTextArea: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        color: "#606060",
        lineHeight: "35px",
        maxWidth: "450px",
        margin: "auto",
        marginTop: "15px"
    },
    responsiveGrid: {
        maxWidth: "100%",
        backgroundColor: "transparent",
        margin: "auto",
    },
    card: {
        minHeight: "280px",
        textAlign: "left",
        borderRadius: "10px"
    },
    cardContent: {
        marginTop: "35px"
    },
    avatar: {
        backgroundColor: globals.fontColor
    }
}));

export default function Details2() {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid id="details2" container spacing={3} className={classes.detailsContainer}>
            <Grid container id="details2Div" spacing={3} className={classes.brandingContainer}>
                <Grid item xs={6} className={classes.brandingLeftDiv}>
                    <img src={brandingImage} alt="details2" className={classes.brandingImage} />
                </Grid>
                <Grid item xs={6} className={classes.brandingRightDiv}>
                    <Typography variant="h1" >
                        <Fade right>
                            <p className={classes.brandingText}>
                                EXCLUSIVIDADE DE VISTA ETERNA PARA O MAR!
                            </p>
                        </Fade>
                    </Typography>
                    <Typography variant="h5" >
                        <Fade>
                            <p className={classes.brandingTextArea}>
                                Apartamentos de 02 ou 03 dormitórios, com 01 Suíte, sendo de 60 m² a 82 m² respectivamente, todos com vista para o mar. As unidade terão uma varanda grill com infra de elétrica e hidráulica.
                            </p>
                        </Fade>
                    </Typography>
                </Grid>
            </Grid> 
        </Grid>
    )
}