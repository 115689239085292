import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        color: "#606060",
        fontSize: "10px",
        fontFamily: "Poppins, sans-serif",
        height: "50px",
        display: "inline-table",
        maxWidth: "100%",
        backgroundColor: "#f6f6f6",
    },
    responsiveGrid: {
        display: "table-cell",
        verticalAlign:"middle",
        maxWidth: "100%",
    },
}));

export default function SanPaoloText() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container spacing={3} style={{ backgroundColor: "#f6f6f6", marginTop: "0px", maxWidth: "100%", margin: "auto" }}>
            <Grid container className={classes.responsiveContainer}>
                <Grid item xs={12} className={classes.responsiveGrid}>
                    <p>RI14/55.455. Informações preliminares sujeitas a alterações. Todas as imagens perspectivas contidas neste material são meramente ilustrativas, podendo sofrer alterações. As unidades serão entregues conforme está detalhado no Memorial Descritivo.
</p>
                </Grid>
            </Grid>
        </Grid>
    )
}
