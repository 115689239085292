import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import facebookImage from '../../images/icon-facebook.jpg'
import instagramImage from '../../images/icon-instagram.jpg'
import ContactForm from '../contactForm/ContactForm'
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        display: 'inline-block',
        margin: "auto",
        width: "100%",
        paddingTop: "30px",
        paddingLeft: "5px",
        paddingRight: "5px",
        backgroundColor: "#f6f6f6",
        paddingBottom: "100px",
        marginBottom: "0px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingLeft: "70px",
            paddingRight: "70px",
        },
    },
    responsiveLeft: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingLeft: "9vw !important",
            paddingTop: "3vw !important",
        }
    },
    responsiveRight: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            paddingRight: "9vw !important",
            paddingTop: "3vw !important",
        }
    },
    responsiveContactUs: {
        color: "#353535",
        fontWeight: "600",
        fontSize: "36px",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
    },
    responsiveImage: {
        marginRight: "10px",
        maxWidth: "30px",
        [theme.breakpoints.up('md')]: {
            width: "30px",
            
        }
    },
    responsiveText: {
        display: 'none',
        color: "#606060",
        fontSize: "30px",
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    responsiveTextArea: {
        display: 'none',
        color: "#606060",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    phoneText: {
        display: 'none',
        color: "#606060",
        fontSize: "22px",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    }
}));

export default function ContactUs() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="contactUs" container spacing={3} className={classes.responsiveContainer}>
            <Grid item xs={12}>
                <Typography variant="h2" >
                    <p className={classes.responsiveContactUs}>{t('contactus title')}</p>
                </Typography>
            </Grid>
            <Grid id="contactUsLeft" item xs={6} className={classes.responsiveLeft}>
                <Typography variant="h5" >
                    <p className={classes.responsiveText}>Incorporadora San Siro LTDA.</p>
                    <p className={classes.responsiveTextArea}>Av. Ivo Gonçalves da Relva.</p>
                    <p className={classes.responsiveTextArea}>Jardim Britânia / Caraguatatuba.</p>
                    <p></p>
                    <p className={classes.responsiveTextArea}>contato@sansiroincorporadora.com.br</p> 
                    <p className={classes.phoneText}>Tel (11) 99858-9652</p>  
                </Typography>
                <a href="https://www.facebook.com/incorpsansiro" target="_blank">
                    <img src={facebookImage} alt="facebook" className={classes.responsiveImage} />
                </a>
                <a href="https://instagram.com/incorpsansiro" target="_blank">
                    <img src={instagramImage} alt="instagram" className={classes.responsiveImage} />
                </a>
            </Grid>
            <Grid id="contactUsRight" item xs={6} className={classes.responsiveRight}>
                <ContactForm />
            </Grid>
        </Grid>
    )
}