import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
import globals from '../globals'

const useStyles = makeStyles((theme) => ({
    websitesContainer: {
        backgroundColor: "white",
        display: "flex",
        flexFlow: "column-reverse",
        margin: "auto",
        maxWidth: globals.maxWidth,
        textAlign: "center",
        paddingBottom: "5vw !important",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",
            marginTop: "15px"
        }
    },
    websitesLeftDiv: {
        maxWidth: "100%",
        margin: "auto",
        display: "block",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "8vw!important",
            paddingLeft: "4vw !important",
            marginTop: "0px",
            backgroundColor: "transparent"
        }
    },
    websitesRightDiv: {
        maxWidth: "100%",
        marginTop: "5vw",
    },

    websitesText: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 500,
        fontSize: "32px",
        textTransform: "uppercase",
        maxWidth: "100%",
        lineHeight: "35px",
        margin:"auto",
        marginTop: "25px",
        
        marginBottom: "25px",
        [theme.breakpoints.up('md')]: {
            float: "left",
        }
    },
    websitesTextArea: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        color: "#606060",
        lineHeight: "35px",
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            float: "left",
            textAlign: "left"
        }
    },
    websitesImage: {
        objectFit: "cover",
        height: "auto",
        width: "80%",
        [theme.breakpoints.up('md')]: {
            width: "100%",
        }
    },
}));

export default function ImobiliariasDetails() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="details" container spacing={3} className={classes.websitesContainer}>
            <Grid id="detailsDiv" item xs={12} className={classes.websitesLeftDiv}>
                    <Fade left>
                        <p className={classes.websitesText}>Imobiliárias</p>
                    </Fade>
                    <Fade>
                        <p className={classes.websitesTextArea}>
                        Seja nosso parceiro, cadastre sua imobiliária com a gente e trabalhe na comercialização de nossos produtos!
                        </p>
                    </Fade>
                
            </Grid>
        </Grid>
    )
}