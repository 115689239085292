import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next"
import globals from '../globals'
import ReactPlayer from 'react-player/youtube'

const useStyles = makeStyles((theme) => ({
    aboutUsContainer: {
        display: "inline-block",
        minHeight: "800px",
        maxWidth: "100%",
        paddingBottom: "80px",
        paddingTop: "40px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            display: "flex",
            overflow: "hidden",
            maxWidth: "1500px"
        }
    },
    avatar: {
        backgroundColor: globals.fontColor
    },
    title1: {
        fontSize: "32px",
        fontWeight: "700",
        maxWidth: "450px",
        color: "#696969",
        lineHeight: "0px",
        fontFamily: "Poppins, sans-serif",
        margin: "auto",
        marginTop: "40px",
        marginBottom: "40px",
        textTransform: "uppercase",
        [theme.breakpoints.up('md')]: {
            lineHeight: "inherit"
        }
    },
    brandingTopDiv: {
        margin: "auto",
        marginTop: "40px",
        width: "100%"
    },
    brandingBottomDiv: {
        margin: "auto",
        maxWidth: "1000px",
        height: "600px"
    },
}));

export default function SanPaoloVideo() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="video"  name="video" container spacing={3} style={{ backgroundColor: "white", maxWidth: "100%", margin:"auto", minHeight:"800px" }}>
            <Grid container spacing={3} className={classes.aboutUsContainer}>
                <Grid  item xs={12} className={classes.brandingTopDiv}>
                    <Typography variant="h2" >
                        <p className={classes.title1}>Vídeo</p>
                    </Typography>
                </Grid>
                
                <Grid  item xs={12} className={classes.brandingBottomDiv}>
                    <ReactPlayer 
                        width="100%"
                        height="100%" 
                        url="https://youtu.be/Htgjok7-stY" 
                    />
                </Grid>
                
            </Grid>
        </Grid>
    )
}