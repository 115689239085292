import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
import globals from '../globals'
import bg from '../../images/img-imobiliarias.jpg';
import Button from '@material-ui/core/Button'
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    devImage: {
        marginLeft: "5%",
        width: "300px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            objectFit: "cover",
            width: "400px",
        },
    },
    devBg: {
        borderRadius: "0px",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        minHeight: "300px",
        maxWidth: "100%",
        margin:"auto",
        [theme.breakpoints.up('md')]: {
            minHeight: "500px",
        }
    },
    leftDiv: {
        maxWidth: "100%",
        float:"center",
        [theme.breakpoints.up('md')]: {
            textAlign: "center",
            paddingLeft: "4vw !important",
            paddingTop: "10vw !important",
        }
    },
    rightDiv: {
        maxWidth: "100%",
        paddingBottom: "1vw",
        paddingTop: "1vw",
        [theme.breakpoints.up('md')]: {
            paddingTop: "4vw",
        }
    },
    subTitle: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            color: "white",
            fontWeight: "400",        
            fontFamily: "Poppins, sans-serif",
            fontSize: "30px",
            width: "40%",
            margin: "auto",
            display: "block"
        }
    },
    subTitleMobile: {
        color: "white",
        fontWeight: "400",        
        fontFamily: "Poppins, sans-serif",
        fontSize: "30px",
        width: "40%",
        margin: "auto",
        display: "block",
        marginTop: "55px",
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
        
    },
    text: {
        color: globals.fontColor,
        fontSize: globals.textSize,
    },
    itemContato: {
        fontSize: "14px", 
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#FFCE2D", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        position: "relative",
        bottom: "10%",
        marginTop: "50px",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
}));

export default function KnowMore() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="knowmore" container spacing={3} className={classes.devBg}>
            <Grid id="knowmoreLeft" item xs={12} className={classes.leftDiv}>
                <Fade left>
                    <Typography variant="h1" style={{width:"100%"}}>
                        <p className={classes.subTitle}>Você tem uma imobiliária e quer se cadastrar para trabalhar com a gente?</p>
                        <p className={classes.subTitleMobile}>Imobiliária? Entre em contato com a gente!</p>
                    </Typography>
                    <Link to="/imobiliarias" style={{textDecoration: "none"}}>
                        <Button
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            className={classes.itemContato}
                        >
                            Saiba mais
                        </Button>
                    </Link>
                </Fade>
            </Grid>
        </Grid>
    )
}