import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import alan from '../../images/socios-alan.jpg';
import jose from '../../images/socios-josemascarenhas.jpg';
import rodrigo from '../../images/socios-rodrigomartins.jpg';

const useStyles = makeStyles((theme) => ({
    ownersContainer: {
        backgroundColor: "white",
        display: "block",
        margin: "auto",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: "12vw !important",
        paddingRight: "12vw !important",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",
            marginTop: "15px",
        }
    },
    ownersRow: {
        minHeight: "350px",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        marginBottom: "40px",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            textAlign: "auto"
        }
    },
    ownersPictureDiv: {
        height: "100%",
        display: "flex",
        margin: "auto",
        minWidth: "275px",
        [theme.breakpoints.up('md')]: {
        }
    },
    ownerPicture: {
        float: "left",
    },
    ownersTextDiv: {
        textAlign: "center",
        display: "block",
        minWidth: "100%",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            minWidth: "auto"
        }
    },
    ownerName: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "35px",
        marginBottom: "5px",
        marginTop: "35px",
    },
    ownerDescription: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
        color: "#606060",
        lineHeight: "35px",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            float: "left",
            textAlign: "left"
        }
    },
    ownerDescriptionItalic: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
        color: "#606060",
        lineHeight: "35px",
        textAlign: "center",
        fontStyle: "italic",
        marginTop: "0px",
        [theme.breakpoints.up('md')]: {
            float: "left",
            textAlign: "left"
        }
    },
}));

export default function Owners() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="owners" container spacing={3} className={classes.ownersContainer}>
            

            <Grid container xs={12} className={classes.ownersRow}>
                <Grid item xs={4} className={classes.ownersPictureDiv}>    
                    <img src={jose} className={classes.ownerPicture}/>
                </Grid>
                <Grid item xs={8} className={classes.ownersTextDiv}>
                    <p className={classes.ownerName}>José Mascarenhas Neto</p>
                    <p className={classes.ownerDescriptionItalic}>Founder e Presidente do Conselho</p>
                    <p className={classes.ownerDescription}>Engenheiro Mecânico formado pela Poli-USP, com mais de 15 anos de experiência no mercado de gerenciamento de projetos de sistemas de proteção contra incêndio em plantas industriais, sócio na Mind Serviços Especializados Ltda. e na Incorporadora San Siro.  </p>
                </Grid>
            </Grid>

            <Grid container xs={12} className={classes.ownersRow}>
            <Grid item xs={4} className={classes.ownersPictureDiv}>    
                <img src={rodrigo} className={classes.ownerPicture}/>
            </Grid>
            <Grid item xs={8} className={classes.ownersTextDiv}>
                <p className={classes.ownerName}>Rodrigo Martins</p>
                <p className={classes.ownerDescriptionItalic}>Founder e Diretor Operacional</p>
                <p className={classes.ownerDescription}>Founder - Engenheiro Civil formado pela Poli - USP com experiência de 2 décadas nas maiores obras de infraestrutura do país, incluíndo linhas de Metrô, rodovias e viadutos. Desde 2018 empreendendo no mercado imobiliário por meio de Start ups, sendo a San Siro seu grande carro-chefe, em que busca implantar conceitos modernos de gestão de pessoas, atendimento personalizado a clientes e investidores e tecnologias inovadoras.</p>
            </Grid>
            </Grid>
            <Grid container xs={12} className={classes.ownersRow}>
                <Grid item xs={4} className={classes.ownersPictureDiv}>    
                    <img src={alan} className={classes.ownerPicture}/>
                </Grid>
                <Grid item xs={8} className={classes.ownersTextDiv}>
                    <p className={classes.ownerName}>Alan Lindsey</p>
                    <p className={classes.ownerDescriptionItalic}>Sócio e Membro do Conselho</p>
                    <p className={classes.ownerDescription}>Engenheiro Civil formado pela POLI-USP, com pós graduação em Administração de Empresas pela FIA. Possui extensa experiência em empresas globais de consultoria e tecnologia, e responsável pela estruturação e implantação de grandes programas de transformação, processos, tecnologia e iniciativas de transformação digital.</p>
                </Grid>
            </Grid>
        </Grid>
    )
}