import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next";
import globals from '../globals'
import Fade from 'react-reveal/Fade';
import img1 from '../../images/img-excl-living-02dorms.jpg';
import img2 from '../../images/img-excl-living-03dorms.jpg';
import img3 from '../../images/img-excl-living-suite.jpg';

const useStyles = makeStyles((theme) => ({
    aboutUsContainer: {
        display: "inline-block",
        minHeight: "800px",
        maxWidth: "100%",
        paddingBottom: "80px",
        paddingTop: "40px",
        paddingRight: "10px",
        paddingLeft: "10px",
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            display: "flex",
            overflow: "hidden",
            margin: "auto",
            maxWidth: "1500px"
        }
    },
    aboutUs: {
        color: globals.titleColor,
        fontWeight: 800,
        fontFamily: 'Roboto',
        lineHeight: "85%",
        fontSize: globals.titleSize,
        textTransform: "uppercase",
        marginTop: "100px",
        [theme.breakpoints.up('md')]: {
            fontWeight: 800,
            fontFamily: 'Roboto',
            lineHeight: "85%",
        }
    },
    aboutUsTextArea: {
        fontFamily: 'Roboto',
        fontSize: "25px",
        color: globals.fontColor,
        marginBottom: "100px",
        [theme.breakpoints.up('md')]: {
            fontSize: globals.textSize,
        }
    },
    responsiveGrid: {
        maxHeight: "500px",
        backgroundColor: "transparent",
        margin: "auto",
        marginTop: "0px",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
        [theme.breakpoints.up('md')]: {
            
        }
    },
    avatar: {
        backgroundColor: globals.fontColor
    },
    title1: {
        fontSize: "32px",
        fontWeight: "700",
        maxWidth: "450px",
        color: "white",
        fontFamily: "Poppins, sans-serif",
        margin: "auto",
        marginTop: "40px",
        marginBottom: "40px",
        textTransform: "uppercase",
        [theme.breakpoints.up('md')]: {
            lineHeight: "inherit"
        }
    },
    brandingContainer: {
        backgroundColor: "white",
        display: "inline-block",
        margin: "auto",
        marginBottom: "40px",
        [theme.breakpoints.up('md')]: {
            display: "flex",
            maxWidth: globals.maxWidth,
        },
    },
    brandingTopDiv: {
        marginTop: "40px",
        width: "100%",
        paddingRight: "0px !important"
    },
    brandingLeftDiv: {
        maxWidth: "100%",
        margin: "0px",
    },
    brandingRightDiv: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "60px !important",
            paddingLeft: "2vw !important",
        }
    },
    brandingGridText: {
        textAlign: "center",
        fontWeight: "500",
        fontFamily: "Poppins, sans-serif",
        fontSize: "26px",
        maxWidth: "290px",
        color: "white",
        margin: "auto",
        marginTop: "20px"
    },
    itemContato: {
        fontSize: "14px", 
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#FFCE2D", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        position: "relative",
        bottom: "10%",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
      tabatinga: {
          fontSize: "34px",
          fontWeight: "800",
          fontStyle: "italic",
          textTransform: "uppercase",
          lineHeight: "32px"
      },
      tabatingaInfo: {
        fontSize: "18px",
        fontWeight: "400",
        textTransform: "uppercase"
      },
      tabatingaCardContent: {
        color: "#474747",
        paddingTop: "0px",
        maxWidth: "200px",
        margin: "auto"
    },
    releases:{
        backgroundColor: "#548794", 
        
        minHeight:"800px",
        [theme.breakpoints.up('md')]: {
            margin:"auto",
            maxWidth: "100%",
        }
    }
}));

export default function SanPaoloMoreDetails() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="moreDetails"  name="moreDetails" container spacing={3} className={classes.releases}>
            <Grid container spacing={3} className={classes.aboutUsContainer}>
                <Grid  item xs={12} className={classes.brandingTopDiv}>
                    <Typography variant="h2" >
                        <p className={classes.title1}>Exclusividade, conforto e tecnologia</p>
                    </Typography>
                </Grid>
                <Grid id="solutions1" item xs={12} md={4} className={classes.responsiveGrid}>
                    <Fade>
                        <img src={img1} alt="img 1" className={classes.img} />
                        <p className={classes.brandingGridText}>Living - Apartamento 02 dormitórios</p> 
                        <Typography variant="h2" >    
                            
                        </Typography>
                    </Fade>
                </Grid>
                <Grid id="solutions2" item xs={12} md={4} className={classes.responsiveGrid}>
                    <Fade>
                        <img src={img2} alt="img 1" className={classes.img} />
                        <Typography variant="h2" >
                            <p className={classes.brandingGridText}>Living - Apartamento 03 dormitórios</p>
                        </Typography>
                    </Fade>
                </Grid>
                <Grid id="solutions2" item xs={12} md={4} className={classes.responsiveGrid}>
                    <Fade>
                        <img src={img3} alt="img 1" className={classes.img} />
                        <Typography variant="h2" >
                            <p className={classes.brandingGridText}>Suíte - Apartamento 03 dormitórios</p>
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    )
}