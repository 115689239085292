import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTo(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    console.log("patrhname " + pathname)
    console.log("scrolling to " + props.value)
    document.getElementById(props.value).scrollIntoView();
  });

  return null;
}