import React from 'react'
import ImobiliariasMain from '../imobiliariasmain/ImobiliariasMain'
import ImobiliariasDetails from '../imobiliariasdetails/ImobiliariasDetails'
import ContactUs from '../contactUs/ContactUs'
import { makeStyles } from '@material-ui/core'
import backgroundImage from '../../images/img-imobiliarias-pag.jpg'
import Footer from '../footer/Footer';


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${backgroundImage})`
    }
}));
export default function Imobiliarias() {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.container} style={{backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <ImobiliariasMain />
            </div>
            <ImobiliariasDetails />
            <ContactUs />
            <Footer />
        </div>
    )
}