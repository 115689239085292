import React from 'react'
import Grid from '@material-ui/core/Grid'
import marketingImage from '../../images/img-varanda.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
    websitesContainer: {
        backgroundColor: "white",
        display: "flex",
        flexFlow: "column-reverse",
        margin: "auto",
        textAlign: "center",
        paddingBottom: "5vw !important",
        paddingLeft: "8vw !important",
        paddingRight: "8vw !important",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",
            marginTop: "15px",
            maxWidth: "100%"  
        }
    },
    websitesLeftDiv: {
        maxWidth: "100%",
        margin: "auto",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "8vw!important",
            paddingLeft: "4vw !important",
            marginTop: "0px",
            backgroundColor: "transparent"
        }
    },
    websitesRightDiv: {
        maxWidth: "100%",
    },

    websitesText: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 500,
        fontSize: "32px",
        textTransform: "uppercase",
        maxWidth: "450px",
        lineHeight: "35px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            marginLeft:"0px"
        }
    },
    websitesTextArea: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        color: "#606060",
        lineHeight: "35px",
        margin: "auto",
        marginTop: "15px"
    },
    websitesImage: {
        objectFit: "cover",
        height: "auto",
        width: "80%",
        [theme.breakpoints.up('md')]: {
            width: "100%",
        }
    },
}));

export default function Details3() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="details3" container spacing={3} className={classes.websitesContainer}>
            <Grid id="details3Div" item xs={6} className={classes.websitesLeftDiv}>
                    <Fade left>
                        <p className={classes.websitesText}>VARANDA GOURMET</p>
                    </Fade>
                    <Fade>
                        <p className={classes.websitesTextArea}>
                            O edifício possui 03 andares e 14 apartamentos, sendo 01 unidade no térreo do tipo Studio, com 35m², e as demais com 60m², incluíndo 02 dormitórios sendo 01 suíte, 
                            varanda gourmet integrando os ambientes, sala, cozinha e área de serviço, e 01 vaga de garagem por unidade.
                        </p>
                    </Fade>
            </Grid>
            <Grid id="websitesRight" item xs={6} className={classes.websitesRightDiv}>
                <img src={marketingImage} alt="websites" className={classes.websitesImage} />
            </Grid>
        </Grid>
    )
}
