import React from 'react'
import InvestidorMain from '../investidormain/InvestidorMain'
import InvestidorDetails from '../investidordetails/InvestidorDetails'
import ContactUs from '../contactUs/ContactUs'
import { makeStyles } from '@material-ui/core'
import backgroundImage from '../../images/img-investidores.jpg'
import Footer from '../footer/Footer';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${backgroundImage})`
    }
}));
export default function Investidor() {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.container} style={{backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <InvestidorMain />
            </div>
            <InvestidorDetails />
            <ContactUs />
            <Footer />
        </div>
    )
}