import React from 'react'
import SansiroBuildingMain from '../sansiroBuildingMain/SansiroBuildingMain'
import Details2 from '../details2/Details2'
import Details from '../details/Details'
import Details3 from '../details3/Details3'
import Releases from '../releases/Releases'
import ContactUs from '../contactUs/ContactUs'
import KnowMore from '../knowmore/KnowMore'
import { makeStyles } from '@material-ui/core'
import Delivered from '../delivered/Delivered'
import GridList from '../gridlist/GridList'
import backgroundImage from '../../images/img-header-edsansiro.jpg'
import Footer from '../footer/Footer';
import VirtualTour from '../virtualtour/VirtualTour'
import Spaces from '../spaces/Spaces'
import ValuesTable from '../valuestable/ValuesTable'
import SansiroVideo from '../sansiroVideo/SansiroVideo'


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${backgroundImage})`
    }
}));
export default function SansiroBuilding(props) {
    const classes = useStyles()
    if (props.scrollTo) {
        window.addEventListener("load", function(){
            console.log("scrolling to " + props.scrollTo)
            document.getElementById(props.scrollTo).scrollIntoView();
        });
    }
    
    return (
        <div>
            <div id="buildingDiv" className={classes.container} style={{minHeight: "1000px", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <SansiroBuildingMain />
            </div>
            <Details more="false" />
            <Details2 />
            <Details3 /> 
            <Spaces />
            <VirtualTour />
            {/* <ValuesTable /> */}
            <SansiroVideo />
            <ContactUs />
            <Footer />
        </div>
    )
}