import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next"
import globals from '../globals'
import img1 from '../../images/img-emandamento-sansiro-01.jpg'
import img2 from '../../images/img-emandamento-sansiro-02.jpg'
import img3 from '../../images/img-emandamento-sansiro-03.jpg'
import img4 from '../../images/img-emandamento-sansiro-04.jpg'
import img5 from '../../images/img-emandamento-sansiro-05.jpg'
import spImg1 from '../../images/img-emandamento-sanpaolo-jun23-1.jpg'
import spImg2 from '../../images/img-emandamento-sanpaolo-jun23-2.jpg'
import spImg3 from '../../images/img-emandamento-sanpaolo-jun23-3.jpg'
import spImg4 from '../../images/img-emandamento-sanpaolo-jun23-4.jpg'

import Carousel from 'react-material-ui-carousel'
import { Link } from "react-router-dom"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    hSeparator: {
        borderBottom: "2px solid #CCC",
        display: "inline-block",
        height: "1px",
        width: "60%",
        [theme.breakpoints.up('md')]: {
            display: "none",
        }
    },
    vSeparator: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            borderRight: "2px solid #CCC",
            display: "inline-block",
            maxWidth: "1px",
            height: "400px",
            marginTop: "12%",
            paddingLeft: "0px !important",
            paddingRight: "0px !important"
        }
    },
    andamentoContainer: {
        display: "inline-block",
        paddingTop: "40px",
        paddingLeft: "12px",
        paddingRight: "12px",
        
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            display: "flex",
            overflow: "hidden",
            margin: "auto",
            maxWidth: "100%",
        }
    },
    avatar: {
        backgroundColor: globals.fontColor
    },
    title1: {
        color: "#353535",
        fontWeight: 500,
        fontFamily: "Oswald, sans-serif",
        fontSize: "32px",
        textTransform: "uppercase",
        maxWidth: "450px",
        lineHeight: "0px",
        margin: "auto",
        marginTop: "40px",
        marginBottom: "40px",
        [theme.breakpoints.up('md')]: {
            lineHeight: "inherit"
        }
    },
    brandingTopDiv: {
        [theme.breakpoints.up('md')]: {
            margin: "auto",
            marginTop: "40px",
            width: "100%"
        }
    },
    brandingBottomDiv: {
        margin: "auto",
        maxWidth: "1000px",
        [theme.breakpoints.up('md')]: {
            height: "100%",
            marginTop: "0px",
            marginBottom: "5px"
        }
    },
    card: {
        height: "auto",
        maxWidth: "600px",
        textAlign: "left",
        border: "0",
        fontSize: globals.textSize,
        color: globals.fontColor,
        margin: "auto",
        backgroundColor: "transparent"
    },
    cardContent: {
        minHeight: "100%",
        padding: "0px",
        textAlign: "center"
    },
    cardImage: {
        width: "100%"
    },
    cardArea: {
        paddingLeft: "20px",
        textAlign: "center",
        maxWidth: "200px",
        margin: "auto"
    },
    cardTitle: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
        fontWeight: "bold",
        marginTop: "30px"
    },
    cardText: {
        marginTop: "18px",
        color: "#a0a0a0",   
        verticalAlign: "middle !important"
    },
    cardTextArea: {
        color: "#606060",
        marginTop: "16px",
        verticalAlign: "middle !important"
    },
    itemContato: {
        fontSize: "20px", 
        fontWeight: "400",
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#f7a50f", 
        borderRadius: "0px",
        margin: "auto",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginTop: "20px",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
}));

export default function SanPaoloAndamento() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="andamento" name="andamento" container spacing={3} style={{ backgroundColor: "#efefef", maxWidth: "100%", margin:"auto", minHeight:"800px", paddingBottom: "80px" }}>
            <Grid id="andamentoContainer" container spacing={3} className={classes.andamentoContainer}>
                
                <Grid id="andamentoLeft" item xs={12}  md={5} className={classes.brandingBottomDiv}>
                    <Grid  item xs={12} className={classes.brandingTopDiv}>
                        <Typography variant="h2" >
                            <p className={classes.title1}>Entregues</p>
                        </Typography>
                    </Grid>
                
                    <Card className={classes.card} variant="outlined">
                        <CardContent className={classes.cardContent}>
                            <Carousel >
                                <img key="0" src={img1} alt="sansiro1" className={classes.cardImage} />
                                <img key="1" src={img2} alt="sansiro2" className={classes.cardImage} />
                                <img key="2" src={img3} alt="sansiro3" className={classes.cardImage} />
                                <img key="3" src={img4} alt="sansiro4" className={classes.cardImage} />
                                <img key="3" src={img5} alt="sansiro4" className={classes.cardImage} />
                            </Carousel>
                        </CardContent>
                        <div className={classes.cardArea}>
                            <p className={classes.cardTitle}>Edifício San Siro</p>
                            <p className={classes.cardText}>100% VENDIDO</p>
                            <p className={classes.cardTextArea}>Bairro Jardim Britânia  Caraguatatuba - SP</p>
                        </div>
                    </Card>

                    <Link to={`/edificio-sansiro`} style={{textDecoration: "none"}}>
                        <Button
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            className={classes.itemContato}
                        >
                            Saiba mais!
                        </Button>
                    </Link>
                </Grid>

                <Grid id="separatorVertical" item xs={12}  md={2} className={classes.vSeparator}>
                    <div id="separator"  ></div>
                </Grid>
                <Grid id="separatorHorizontal" item xs={12}  md={2} className={classes.hSeparator}>
                    <div id="separator"  ></div>
                </Grid>

                <Grid  id="andamentoRight" item xs={12}  md={5} className={classes.brandingBottomDiv}>
                    <Grid  item xs={12} className={classes.brandingTopDiv}>
                        <Typography variant="h2" >
                            <p className={classes.title1}>Em Andamento</p>
                        </Typography>
                    </Grid>
                
                    <Card className={classes.card} variant="outlined">
                        <CardContent className={classes.cardContent}>
                            <Carousel>
                                <img key="0" src={spImg1} alt="sanpaolo1" className={classes.cardImage} />
                                <img key="1" src={spImg2} alt="sanpaolo2" className={classes.cardImage} />
                                <img key="2" src={spImg3} alt="sanpaolo3" className={classes.cardImage} />
                                <img key="3" src={spImg4} alt="sanpaolo4" className={classes.cardImage} />
                            </Carousel>
                        </CardContent>
                        <div className={classes.cardArea}>
                            <p className={classes.cardTitle}>Edifício San Paolo</p>
                            <p className={classes.cardText}>OBRAS ACELERADAS</p>
                            <p className={classes.cardTextArea}>Praça Eng. M. Parolari  Caraguatatuba - SP</p>
                        </div>
                    </Card>
                    <Link to={`/`} style={{textDecoration: "none"}}>
                        <Button
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            className={classes.itemContato}
                        >
                            Saiba mais!
                        </Button>
                    </Link>
                </Grid>
                
            </Grid>
        </Grid>
    )
}