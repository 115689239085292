import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
    websitesContainer: {
        backgroundColor: "white",
        margin: "auto",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: "12vw !important",
        paddingRight: "12vw !important",
        paddingBottom: "5vw !important",
        [theme.breakpoints.up('md')]: {
            flexFlow: "inherit",
            marginTop: "15px"
        }
    },
    websitesLeftDiv: {
        maxWidth: "100%",
        margin: "auto",
        paddingLeft: "4vw !important",
        paddingRight: "4vw !important",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            marginTop: "0px",
            backgroundColor: "transparent"
        }
    },
    websitesText: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        fontWeight: 500,
        fontSize: "32px",
        textTransform: "uppercase",
        maxWidth: "450px",
        lineHeight: "35px",
        margin:"auto",
        marginTop: "100px",
        [theme.breakpoints.up('md')]: {
            float: "left",
        }
    },
    websitesTextArea: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        color: "#606060",
        lineHeight: "35px",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            float: "left",
            textAlign: "left"
        }
    },
    websitesImage: {
        objectFit: "cover",
        height: "auto",
        width: "80%",
        [theme.breakpoints.up('md')]: {
            width: "100%",
        }
    },
}));

export default function SobreDetails() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="sobredetails" container spacing={3} className={classes.websitesContainer}>
            <Grid id="sobredetailsDiv" item xs={12} className={classes.websitesLeftDiv}>
                    <Fade left>
                        <p className={classes.websitesText}>San Siro Incorporadora</p>
                    </Fade>
                    <Fade>
                        <p className={classes.websitesTextArea}>
                        Empresa formada por profissionais com 20 anos de experiência em grandes empreendimentos de engenharia, obras de infraestrutura, mercado financeiro, tecnologia e indústria em geral. Com o propósito de criar habitações modernas e inovadoras a um preço justo, buscando contribuir para o aumento da qualidade de vida das pessoas.
                        </p>
                    </Fade>
            </Grid>
        </Grid>
    )
}