import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        color: "white",
        fontFamily: "Poppins, sans-serif",
        height: "50px",
        display: "inline-table",
        maxWidth: "100%",
        textDecoration: "none",
    },
    responsiveGrid: {
        display: "table-cell",
        verticalAlign:"middle",
        maxWidth: "100%",
    },
    privacy: {
        color: "white", 
    }
}));

export default function Footer() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container spacing={3} style={{ backgroundColor: "#353535", marginTop: "0px", maxWidth: "100%", margin: "auto" }}>
            <Grid container className={classes.responsiveContainer}>
                <Grid item xs={12} className={classes.responsiveGrid}>
                    <p>Criado por BluePear Agency | <Link to={`/politica-de-privacidade`} className={classes.privacy}>Política de Privacidade</Link></p>
                </Grid>
            </Grid>
        </Grid>
    )
}
