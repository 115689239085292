import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next";
import globals from '../globals'
import img1 from '../../images/edsansiro-ambientes-001.jpg';
import img2 from '../../images/edsansiro-ambientes-002.jpg';
import img3 from '../../images/edsansiro-ambientes-003.jpg';
import img4 from '../../images/edsansiro-ambientes-004.jpg';
import img5 from '../../images/edsansiro-ambientes-005.jpg';
import img6 from '../../images/edsansiro-ambientes-006.jpg';
import cardBg from '../../images/icone-embreve.png';
import cardBgPortoNovo from '../../images/img-embreve-praia.jpg';



const useStyles = makeStyles((theme) => ({
    aboutUsContainer: {
        display: "inline-block",
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            display: "flex",
            overflow: "hidden",
            margin: "auto",
            maxWidth: "1200px"
        }
    },
    aboutUs: {
        color: globals.titleColor,
        fontWeight: 800,
        fontFamily: 'Roboto',
        lineHeight: "85%",
        fontSize: globals.titleSize,
        textTransform: "uppercase",
        marginTop: "100px",
        [theme.breakpoints.up('md')]: {
            fontWeight: 800,
            fontFamily: 'Roboto',
            lineHeight: "85%",
        }
    },
    aboutUsTextArea: {
        fontFamily: 'Roboto',
        fontSize: "25px",
        color: globals.fontColor,
        marginBottom: "100px",
        [theme.breakpoints.up('md')]: {
            fontSize: globals.textSize,
        }
    },
    responsiveGrid: {
        maxWidth: "100%",
        backgroundColor: "transparent",
        margin: "auto",
        marginBottom:"100px",
        marginTop:"40px"
    },
    card: {
        minHeight: "500px",
        textAlign: "left",
        borderRadius: "0px",
        border: "0",
        backgroundImage: `url(${cardBg})`,
    },
    cardPortoNovo: {
        minHeight: "500px",
        textAlign: "left",
        borderRadius: "0px",
        border: "0",
        backgroundImage: `url(${cardBgPortoNovo})`,
    },
    cardContent: {
        marginTop: "45px",
        color: "white",
        paddingLeft: "80px",
        paddingRight: "70px",
        height: "100%",
        minHeight: "300px",
    },
    avatar: {
        backgroundColor: globals.fontColor
    },
    title1: {
        color: "#353535",
        fontWeight: 500,
        fontFamily: "Oswald, sans-serif",
        fontSize: "30px",
        textTransform: "uppercase"
    },
    title2: {
        fontSize: "36px",
        fontWeight: 900,
        color: "white",
        textTransform: "uppercase",
        fontFamily: "Oswald, sans-serif",
        [theme.breakpoints.up('md')]: {
            margin: "0px",
        }
    },
    brandingContainer: {
        backgroundColor: "white",
        display: "inline-block",
        margin: "auto",
        marginBottom: "40px",
        [theme.breakpoints.up('md')]: {
            display: "flex",
            maxWidth: globals.maxWidth,
        },
    },
    brandingTopDiv: {
        marginTop: "40px",
    },
    brandingLeftDiv: {
        maxWidth: "100%",
        margin: "0px",
    },
    brandingRightDiv: {
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
            paddingTop: "60px !important",
            paddingLeft: "2vw !important",
        }
    },
    brandingGridText: {
        fontWeight: "normal",
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        marginTop: "0px"
    },
    brandingTextArea: {
        fontFamily: "Oswald, sans-serif",
        fontWeight: "bold",
        fontSize: "50px",
        textTransform: "uppercase",
        marginBottom: "10px"
    },
    itemContato: {
        fontSize: "14px", 
        color: "#353535", 
        minHeight: "50px",
        backgroundColor: "#FFCE2D", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        position: "relative",
        bottom: "10%",
        '&:hover': {
          backgroundColor: "#F9D56E",
        },
      },
      grid: {
        width: "100%",
        maxWidth: "700px",
        margin: "auto",
        [theme.breakpoints.up('md')]: {
            
        }
    },
}));

export default function Spaces() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid id="releases"  name="releases" container spacing={3} style={{ backgroundColor: "white", maxWidth: "100%", margin:"auto" }}>
            <Grid container spacing={3} className={classes.aboutUsContainer}>
                <Grid  item xs={12} className={classes.brandingTopDiv}>
                    <Typography variant="h2" >
                        <p className={classes.title1}>Ambientes</p>
                    </Typography>
                </Grid>

                <Grid id="gridlist1" item xs={6} className={classes.grid}>
                    <img src={img1} style={{maxWidth: "100%", height:"auto"}}/>
                </Grid>
                <Grid id="gridlist2" item xs={6} className={classes.grid}>
                    <img src={img2}  style={{maxWidth: "100%", height:"auto"}}/>
                </Grid>
                

                <Grid id="gridlist2" item xs={6} className={classes.grid}>
                    <img src={img3}  style={{maxWidth: "100%", height:"auto"}}/>
                </Grid>
           
                <Grid id="gridlist4" item xs={6} className={classes.grid}>
                    <img src={img4} style={{ height: "auto",maxWidth: "100%"  }}/>
                </Grid>
                <Grid id="gridlist5" item xs={6} className={classes.grid}>
                    <img src={img5} style={{ height: "auto",maxWidth: "100%" }}/>
                </Grid>
           
                <Grid id="gridlist6" item xs={6} className={classes.grid} >
                    <img src={img6} style={{ height: "auto",maxWidth: "100%"  }}/>
                </Grid>
                
            </Grid>
        </Grid>
    )
}