import React from 'react'
import SobreMain from '../sobremain/SobreMain'
import SobreDetails from '../sobredetails/SobreDetails'
import ContactUs from '../contactUs/ContactUs'
import Owners from '../owners/Owners'
import { makeStyles } from '@material-ui/core'
import backgroundImage from '../../images/img-sobre.jpg'
import Footer from '../footer/Footer';


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${backgroundImage})`,
        maxWidth: "100%",
        margin:"auto",
    }
}));
export default function Sobre() {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.container} style={{minHeight: "10px", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <SobreMain />
            </div>
            <SobreDetails />
            <Owners />
            <ContactUs />
            <Footer />
        </div>
    )
}