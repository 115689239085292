import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import parquePrincipeImg from '../../images/img-entregues-pqdosprincipes.jpg'
import parquePrincipeImg2 from '../../images/img-entregues-pqdosprincipes-02.jpg'
import parquePrincipeImg3 from '../../images/img-entregues-pqdosprincipes-03.jpg'
import parquePrincipeImg4 from '../../images/img-entregues-pqdosprincipes-04.jpg'
import parquePrincipeImg5 from '../../images/img-entregues-pqdosprincipes-05.jpg'
import parquePrincipeImg6 from '../../images/img-entregues-pqdosprincipes-06.jpg'
import globals from '../globals'
import Fade from 'react-reveal/Fade'
import Carousel from 'react-material-ui-carousel'



const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        display: 'inline-block',
        margin: "auto",
        marginTop: "20px",
        maxWidth: "100%",
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            maxWidth: "1500px"
        },
    },
    responsiveGrid: {
        maxWidth: "100%",
        margin: "auto",
        paddingLeft: "4vw",
        paddingRight: "4vw",
        [theme.breakpoints.up('md')]: {
            position: "relative"
        }
    },
    emptyGrid: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            display: "block",
            maxWidth: "100%",
            maxHeight: "400px",
            margin: "auto",
            paddingLeft: "4vw",
            paddingRight: "4vw",
            marginBottom: "100px",
            position: "relative"
        }
    },
    responsiveTitle1: {
        color: "#353535",
        fontWeight: 500,
        fontFamily: "Oswald, sans-serif",
        fontSize: "30px",
        textTransform: "uppercase"
    },
    responsiveTitle2: {
        color: globals.titleColor,
        fontWeight: "800",
        fontSize: globals.titleSize,
        textTransform: "uppercase",
        lineHeight: "0px",
    },
    responsiveText: {
        display: 'inline-block',
        color: globals.fontColor,
        fontSize: globals.textSize,
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    card: {
        height: "auto",
        maxWidth: "700px",
        textAlign: "left",
        border: "0",
        fontSize: globals.textSize,
        color: globals.fontColor,
        margin: "auto",
    },
    cardContent: {
        minHeight: "100%",
        padding: "0px",
        textAlign: "center"
    },
    emptyCard: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            backgroundColor: "#D1D1D1",
            color: "#353535",
            minHeight: "300px",
            textAlign: "center",
            display: "block"
        }
    },
    avatar: {
        backgroundColor: globals.fontColor,
        margin: "auto"
    },
    cardTitle: {
        color: "#353535",
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase",
        fontWeight: "bold",
        marginTop: "30px"
    },
    cardText: {
        marginTop: "18px",
        color: "#a0a0a0",   
        verticalAlign: "middle !important"
    },
    cardTextArea: {
        color: "#606060",
        marginTop: "16px",
        verticalAlign: "middle !important"
    },
    cardTextLink: {
        marginTop: "25px",
        textDecoration: "none",
        fontWeight: "bold",
        color: globals.fontDarkColor,
        textAlign: "center"
    },
    cardAction: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            position: "absolute",
            bottom: "25px",
            left: "33%"
        }
    },
    emBreve: {
        display: "inline-table",
        minHeight: "300px",
        textAlign: "center !important"
    },
    emBreveChild: {
        display: "table-cell",
        verticalAlign: "middle",
        color: "#353535",
        fontWeight: 500,
        fontFamily: "Oswald, sans-serif",
        textTransform: "uppercase"
    },
    logo: {
        minWidth: "300px",
    },
    cardArea: {
        paddingLeft: "20px"
    },
    botao: {
        position: "relative",
        top: "240px",
        fontSize: "18px", 
        color: "white", 
        minHeight: "50px",
        minWidth: "150px",
        backgroundColor: "transparent", 
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "70px",
        marginRight: "70px",
        borderRadius: "0px",
        border: "solid",
        borderColor: "#F9D56E",
        borderWidth: "1px",
        fontWeight: 400
    },
}));

export default function Delivered() {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return (
        <Grid id="delivered"  name="delivered" container justify="center" alignItems="center" spacing={3} className={classes.responsiveContainer}>
            <Grid item xs={12}>
                <Typography variant="h2" >
                    <p className={classes.responsiveTitle1}>{t('delivered properties')}</p>
                </Typography>
            </Grid>
            <Grid id="delivered1" item xs={3} className={classes.responsiveGrid}>
                <Fade>
                        <Card className={classes.card} variant="outlined">
                            <CardContent className={classes.cardContent}>
                            <Carousel>
                                <img key="0" src={parquePrincipeImg} alt="parque dos principes" className={classes.logo} />
                                <img key="1" src={parquePrincipeImg2} alt="parque dos principes 2" className={classes.logo} />
                                <img key="2" src={parquePrincipeImg3} alt="parque dos principes 3" className={classes.logo} />
                                <img key="3" src={parquePrincipeImg4} alt="parque dos principes 4" className={classes.logo} />
                                <img key="4" src={parquePrincipeImg5} alt="parque dos principes 5" className={classes.logo} />
                                <img key="5" src={parquePrincipeImg6} alt="parque dos principes 6" className={classes.logo} />
                            </Carousel>
                                <div className={classes.cardArea}>
                                <p className={classes.cardTitle}>Parque dos Príncipes</p>
                                <p className={classes.cardText}>Rua Quinze Caraguatatuba - SP</p>
                                <p className={classes.cardTextArea}>Entrega: Novembro 2020</p>
                                </div>
                            </CardContent>
                        </Card>
                </Fade>
            </Grid>
        </Grid>
    )

}