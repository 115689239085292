import React from 'react'
import './App.css'
import Home from './components/home/Home'
import Sobre from './components/sobre/Sobre';
import Investidor from './components/investidor/Investidor';
import Imobiliarias from './components/imobiliarias/Imobiliarias';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import ScrollToTop from './components/scroll/ScrollToTop'
import ScrollTo from './components/scroll/ScrollTo'
import SansiroBuilding from './components/sansiroBuilding/SansiroBuilding';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
// import SanPaoloBuilding from './components/sanpaoloBuilding/SanPaoloBuilding';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
    {/*  */}
    <div className="App">
      <Switch>
        <Route exact path={`/`}>
          <ScrollToTop />
          <Home />
        </Route>
        <Route path={`/lancamentos`}>
          <Home scrollTo="releases"/>
          <ScrollTo value="releases" />
        </Route>
        <Route path={`/entregues`}>
          <Home scrollTo="delivered"/>
          <ScrollTo value="delivered" />
        </Route>
        <Route path={`/sobre`}>
          <ScrollToTop />
          <Sobre />
        </Route>
        <Route path={`/investidor`}>
          <ScrollToTop />
          <Investidor />
        </Route>
        <Route path={`/imobiliarias`}>
          <ScrollToTop />
          <Imobiliarias />
        </Route>
        <Route path={`/edificio-sansiro`}>
          <ScrollToTop />
          <SansiroBuilding />
        </Route>
        <Route path={`/politica-de-privacidade`}>
          <ScrollToTop />
          <PrivacyPolicy />
        </Route>
        {/* <Route path={`/edificio-sanpaolo`}>
          <ScrollToTop />
          <SanPaoloBuilding />
        </Route> */}
        <Route path="*">
            <NoMatch />
          </Route>
      </Switch>
    </div>
    </I18nextProvider>
  );
}

function NoMatch() {
  let location = useLocation();
  return (
    <div>
      <h3>
        <code>{location.pathname}</code> - Página não encontrada :(
      </h3>
    </div>
  );
}


export default App;
